
import React, { FC } from "react";
import { Row, Col } from "reactstrap";
import { v4 } from "uuid";
import { IndexedTodo } from "./IndexedTodo";
import { Project } from "../../../models/Todo/Project";
import { ArchivedTodoCard } from "../ArchivedTodoCard";


interface DoneProps {
  archives: Array<IndexedTodo>;
}

export const Archive: FC<DoneProps> = ({ archives }) => {
  return (
    <>
      <Row>
        <Col xs="12">
          <h1>Archives</h1>
        </Col>
        <Col xs="12">
          <h3>全て</h3>
        </Col>
        <Col xs="12">
          {archives.map((x: IndexedTodo) => (
            <ArchivedTodoCard key={v4()} todo={x} />
          ))}
        </Col>
      </Row>
    </>
  );
};
