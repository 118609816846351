import { LineWithNumber } from "./LineWithNumber";
import { TodoLineWithNumber } from "./TodoLineWithNumber";

export class TodoAndSubTodos {
  constructor(
    public todoLineWithNumber: TodoLineWithNumber,
    public todoLines: Array<LineWithNumber>,
    public subTodoLines: Array<Array<LineWithNumber>>,
  ) {}
}
