import * as firebase from "firebase";
import React, { FC } from "react";
import { map } from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/pipeable";
import { useGlobal } from "reactn";
import { cloneDeep } from "lodash-es";
import { Controlled as CodeMirror } from "react-codemirror2";
import { Editor as CMEditor } from "codemirror";
import "codemirror/theme/material.css";
import "codemirror/lib/codemirror.css";
import "./Editor.css";
import moment from "moment";
import { TodoFactory } from "../../models/Todo/NewTodoFactory";
import {
  getIndentAndLineBreak,
  getLastBlockLineNumber,
} from "./EditorAnalyser";

import { updateDb } from "../../models/DB/RealtimeDB";

require("codemirror/mode/xml/xml");
require("codemirror/mode/javascript/javascript");
require("codemirror/mode/gfm/gfm");

interface EditorProps {}

export const Editor: FC<EditorProps> = () => {
  const [globalState, setGlobalState] = useGlobal();

  // 現在のTodo階層よりも１つ低い階層
  const deeperIndent = (cm: CMEditor, prefix: string) => {
    const indent = getIndentAndLineBreak(cm.getValue(), cm.getCursor().line);
    if (indent === undefined) {
      cm.replaceSelection(`${prefix}`, "end");
      return;
    }
    cm.replaceSelection(`${indent}${prefix}`, "end");
  };


  const handleChangeEditor = (lines: string) => {
    if (lines === "") {
      const newGlobalState = cloneDeep(globalState);
      newGlobalState.projects = [];
      setGlobalState(newGlobalState);
    }
    const newGlobalState = cloneDeep(globalState);
    newGlobalState.editor = lines;
    setGlobalState(newGlobalState);
    const user = firebase.auth().currentUser;
    updateDb(user, "editor", newGlobalState.editor);

    const parsed = TodoFactory.parse(lines);

    pipe(
      parsed,
      map((x) => {
        const newGlobalState2 = cloneDeep(globalState);
        newGlobalState2.projects = x;
        setGlobalState(newGlobalState2);
        updateDb(user, "projects", JSON.stringify(newGlobalState2.projects));
      }),
    );
  };

  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          position: "absolute",
          right: "0",
          bottom: 0,
          zIndex: 1,
          lineHeight: "1rem",
        }}
      >
        <p style={{ borderBottom: "solid 1px #888888" }}>Alt + P: Project add</p>
        <p style={{ borderBottom: "solid 1px #888888" }}>
          Alt + A: Add new Todo
        </p>
        <p style={{ borderBottom: "solid 1px #888888" }}>
          Alt + D: Deadline add
        </p>
        <p style={{ borderBottom: "solid 1px #888888" }}>Alt + T: Tags add</p>
        <p style={{ borderBottom: "solid 1px #888888" }}>Alt + S: SubTodo add</p>
      </div>
      <CodeMirror
        options={{
          mode: "gfm",
          theme: "material",
          lineNumbers: true,
          tabSize: 2,
          extraKeys: {
            Tab(cm: CMEditor) {
              const now = cm.getCursor();
              cm.execCommand("goLineStart");
              cm.replaceSelection("  ", "end");
              [...Array(now.ch)].forEach(() => cm.execCommand("goCharRight"));
            },
            "Shift-Tab": function (cm: CMEditor) {
              const now = cm.getCursor();
              if (/ {2}.*/.test(cm.getLine(now.line))) {
                cm.execCommand("goLineStart");
                cm.execCommand("delCharAfter");
                cm.execCommand("delCharAfter");
                [...Array(now.ch - 2)].forEach(() =>
                  cm.execCommand("goCharRight"));
              }
            },
            "Alt-A": function (cm: CMEditor) {
              const lastLine = getLastBlockLineNumber(
                cm.getValue(),
                cm.getCursor().line,
              );
              cm.setCursor(lastLine);
              cm.execCommand("goLineEnd");
              cm.replaceSelection("\n", "end");
              cm.execCommand("goLineStart");
              cm.replaceSelection(
                `- [ ] \n  - : ${moment(new Date()).format(
                  "YYYY-MM-DDTHH:mm:ss",
                )}`,
                "end",
              );
              cm.execCommand("goLineUp");
              cm.execCommand("goLineEnd");
            },
            "Alt-P": function (cm: CMEditor) {
              const lastLine = getLastBlockLineNumber(
                cm.getValue(),
                cm.getCursor().line,
              );
              cm.setCursor(lastLine);
              cm.execCommand("goLineEnd");
              cm.replaceSelection("\n## ", "end");
            },
            "Alt-T": function (cm: CMEditor) {
              deeperIndent(cm, "- # ");
            },
            "Alt-D": function (cm: CMEditor) {
              deeperIndent(
                cm,
                `- @ ${moment(new Date()).format("YYYY-MM-DD")}`,
              );
            },
            "Alt-S": function (cm: CMEditor) {
              deeperIndent(cm, "- [ ] ");
            },
          },
        }}
        value={globalState.editor}
        onBeforeChange={(e, d, v) => {
          handleChangeEditor(v);
        }}
        onChange={(e, d, v) => handleChangeEditor(v)}
      />
    </div>
  );
};
