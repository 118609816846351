import * as firebase from "firebase";
import { firebaseDB } from "../../Firebase";
import { Project } from "../Todo/Project";

export interface Global {
  editor: string;
  projects: Array<Project>;
  archives: Array<Project>;
}

export const updateDb = async (
  user: firebase.User | null,
  key: string,
  value: string,
): Promise<any> => {
  if (user === null) {
    return;
  }
  return firebaseDB.ref(`users/${user.uid}`).update({
    [key]: value,
  });
};
