
import React, { FC } from "react";
import {
  Row, Col,
} from "reactstrap";
import { v4 } from "uuid";
import Moment from "moment";
import { TodoCard } from "../TodoCard";
import { IndexedTodo } from "./IndexedTodo";

const sameDate = (day1: Date, day2: Date): boolean => Moment(day1).format("YYYY-MM-DD") === Moment(day2).format("YYYY-MM-DD");
const pastDate = (target: Date): boolean => Moment(target).isBefore(Moment(Date.now()));

interface TodayProps{
  indexedTodos: Array<IndexedTodo>
}

export const Today: FC<TodayProps> = ({
  indexedTodos,
}) => {
  const today = indexedTodos
    .filter((x) => x.todo.deadline !== undefined &&
    !x.todo.done &&
    sameDate(x.todo.deadline.value, new Date()));

  const dead = indexedTodos
    .filter((x) => x.todo.deadline !== undefined &&
  !x.todo.done &&
  pastDate(x.todo.deadline.value));

  return (
    <>
      <Row>
        <Col xs="12">
          <h1>今日</h1>
        </Col>
        <Col xs="12">
          <h3>期限切れ</h3>
        </Col>
        <Col xs="12">
          {dead.map((x: IndexedTodo) => (
            <TodoCard
              key={v4()}
              todo={x}
            />
          ))}
        </Col>
        <Col xs="12">
          <h3>
            今日
          </h3>
        </Col>
        <Col xs="12">
          {today.map((x: IndexedTodo) => (
            <TodoCard
              key={v4()}
              todo={x}
            />
          ))}
        </Col>
      </Row>
    </>
  );
};
