import * as firebase from "firebase";
import React, { FC } from "react";
import { Container, Row, Col } from "reactstrap";
import { v4 } from "uuid";
import Moment from "moment";
import { useGlobal } from "reactn";
import { cloneDeep } from "lodash-es";
import { TodoStringify } from "../../models/Todo/TodoStringify";
import { IndexedSubTodo } from "./TodoBoxes/IndexedSubTodo";
import { updateDb } from "../../models/DB/RealtimeDB";

interface SubTodoCardProps {
  todo: IndexedSubTodo;
}

export const SubTodoCard: FC<SubTodoCardProps> = ({ todo }) => {
  const [globalState, setGlobalState] = useGlobal();

  const handleChangeDone = () => {
    const newGlobalState = cloneDeep(globalState);

    todo.getSubTodo(newGlobalState.projects).done = !todo.getSubTodo(
      newGlobalState.projects,
    ).done;

    // finishedの更新
    if (todo.getSubTodo(newGlobalState.projects).done) {
      todo.getSubTodo(newGlobalState.projects).finished = {
        num: todo.todo.title.num + 1,
        value: new Date(),
      };
    } else {
      todo.getSubTodo(newGlobalState.projects).finished = undefined;
    }

    newGlobalState.editor = TodoStringify.stringify(newGlobalState.projects);
    setGlobalState(newGlobalState);
    updateDb(firebase.auth().currentUser, "editor", newGlobalState.editor);
    updateDb(firebase.auth().currentUser, "projects", JSON.stringify(newGlobalState.projects));
  };

  return (
    <>
      <Container className="sub-todo-card">
        <Row
          className="viewer"
          style={{ borderTop: "dotted 1px #888888" }}
          key={v4()}
        >
          <Col xs="1" onClick={() => handleChangeDone()}>
            {todo.todo.done ? "✔" : "□"}
          </Col>
          <Col xs="11">{todo.todo.title.value}</Col>
          <Col xs="4">
            {todo.todo.deadline ? (
              <p className="deadline">
                {Moment(todo.todo.deadline.value).format("YYYY年MM月DD日")}
              </p>
            ) : (
              <></>
            )}
          </Col>
          <Col xs="4">{todo.todo.project}</Col>
          <Col xs="4">{todo.todo.tag.map((x) => x.value).join("/")}</Col>
        </Row>
        <Row>
          <Col xs="12" style={{ paddingRight: "0" }}>
            {todo.todo.subTodo.map((x, i) => (
              <SubTodoCard
                key={v4()}
                todo={
                  new IndexedSubTodo(
                    todo.projectIndex,
                    todo.todoIndex,
                    [...todo.subIndexes, i],
                    x,
                  )
                }
              />
            ))}
          </Col>
        </Row>
      </Container>
    </>
  );
};
