import { Todo } from "../../../models/Todo/Todo";
import { Project } from "../../../models/Todo/Project";

export class IndexedSubTodo {
  constructor(
    public projectIndex: number,
    public todoIndex: number,
    public subIndexes: Array<number>,
    public todo: Todo,
  ) {}

  getSubTodo(projects: Array<Project>): Todo {
    const parent = projects[this.projectIndex].todo[this.todoIndex];
    return this.subIndexes.reduce((acc, now) => acc.subTodo[now],
      parent);
  }
}
