import * as firebase from "firebase";
import React, { FC } from "react";
import {
  Container, Row, Col, Button,
} from "reactstrap";
import { v4 } from "uuid";
import Moment from "moment";
import { useGlobal } from "reactn";
import { cloneDeep } from "lodash-es";
import { TodoStringify } from "../../models/Todo/TodoStringify";
import { IndexedTodo } from "./TodoBoxes/IndexedTodo";
import { SubTodoCard } from "./SubTodoCard";
import { IndexedSubTodo } from "./TodoBoxes/IndexedSubTodo";
import { Todo } from "../../models/Todo/Todo";
import { Project } from "../../models/Todo/Project";
import { updateDb } from "../../models/DB/RealtimeDB";

interface ArchivedTodoCardProps {
  todo: IndexedTodo
}


export const ArchivedTodoCard: FC<ArchivedTodoCardProps> = ({ todo }) => {
  const [globalState, setGlobalState] = useGlobal();

  const handleUnArchive = () => {
    const newGlobalState = cloneDeep(globalState);

    newGlobalState.archives[todo.projectIndex].todo = newGlobalState.archives[todo.projectIndex].todo.filter((x: Todo, i: number) => i !== todo.index);

    // projectsの中に同名のprojectがあれば、そこにtodoを追加し、なければprojectごと追加する
    const foundProject = newGlobalState.projects.find((x: Project) => x.project === todo.todo.project);

    if (foundProject !== undefined) {
      foundProject.todo.push(todo.todo);
    } else {
      newGlobalState.projects.push(new Project(todo.todo.project, [todo.todo]));
    }

    newGlobalState.editor = TodoStringify.stringify(newGlobalState.projects);
    setGlobalState(newGlobalState);
    updateDb(firebase.auth().currentUser, "editor", newGlobalState.editor);
    updateDb(firebase.auth().currentUser, "projects", JSON.stringify(newGlobalState.projects));
    updateDb(firebase.auth().currentUser, "archives", JSON.stringify(newGlobalState.archives));
  };

  return (
    <>
      <Container className="todo-card">
        <Row className="viewer" key={v4()}>
          <Col xs="12">
            <Button onClick={handleUnArchive}>UnArchive</Button>
          </Col>
          <Col xs="1">
            {todo.todo.done ? "✔" : "□"}
          </Col>
          <Col xs="11">
            {todo.todo.title.value}
          </Col>
          <Col xs="4">
            {todo.todo.deadline?.value ? <p className="deadline">{Moment(todo.todo.deadline.value).format("YYYY年MM月DD日")}</p> : <></>}
          </Col>
          <Col xs="4">
            {todo.todo.project}
          </Col>
          <Col xs="4">
            {todo.todo.tag.map((x) => x.value).join("/")}
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            {todo.todo.subTodo.map((x, i) => (
              <SubTodoCard
                key={v4()}
                todo={new IndexedSubTodo(todo.projectIndex, todo.index, [i], x)}
              />
            ))}
          </Col>
        </Row>
      </Container>
    </>
  );
};
