import React, { useEffect } from "react";
import {
  Container, Row, Col, Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import * as firebase from "firebase";
import { useGlobal } from "reactn";
import objectAssign from "object-assign";
import { State } from "reactn/default";
import { Project } from "../models/Todo/Project";


export const TopNav = () => {
  const [userState, setUserState] = useGlobal("user");
  const [, setEditorState] = useGlobal("editor");
  const [, setProjectsState] = useGlobal("projects");
  const [, setArchivesState] = useGlobal("archives");
  const [, setGlobalState] = useGlobal();

  const init = async (set: (x: State) => void, user: firebase.User | null) => {
    if (user !== null) {
      console.log("aa");
      const editorSS = await firebase.database().ref(`users/${user.uid}/editor`).once("value");
      const projectsSS = await firebase.database().ref(`users/${user.uid}/projects`).once("value");
      const archivesSS = await firebase.database().ref(`users/${user.uid}/archives`).once("value");

      const projects: Array<Project> = [];
      const archives: Array<Project> = [];

      const editorVal = editorSS.val();
      const projectsVal = projectsSS.val();
      const archivesVal = archivesSS.val();

      let editor = "";

      if (editorVal !== undefined) {
        editor = editorVal;
      }

      if (projectsVal !== undefined) {
        objectAssign(projects, JSON.parse(projectsVal));
      }

      if (archivesVal !== undefined) {
        objectAssign(archives, JSON.parse(archivesVal));
      }

      setGlobalState({
        editor,
        projects,
        archives,
        user,
      });

      firebase.database().ref(`users/${user.uid}/editor`).on("value", (snapshot) => {
        setEditorState(snapshot.val());
      });
      firebase.database().ref(`users/${user.uid}/projects`).on("value", (snapshot) => {
        const projectsArray: Array<Project> = [];
        objectAssign(projectsArray, JSON.parse(snapshot.val()));
        setProjectsState(projectsArray);
      });
      firebase.database().ref(`users/${user.uid}/archives`).on("value", (snapshot) => {
        const archiveArray: Array<Project> = [];
        objectAssign(archiveArray, JSON.parse(snapshot.val()));
        setArchivesState(archiveArray);
      });
    }
  };

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      setUserState(user);
      init(setGlobalState, user);
    });
  }, []);

  const logOut = () => {
    firebase.auth().signOut();
    setUserState(null);
  };

  return (
    <>
      <div style={{ height: "6rem" }}>
        <nav>
          <Container>
            <Row>
              <Col xs={{ size: 2 }}>
                <Link to="/">Todo-md</Link>
              </Col>
              <Col xs={{ size: 4, offset: 6 }}>
                {userState
                  ? (
                    <>
                      <Button onClick={logOut}>SignOut</Button>
                    </>
                  )
                  : <Link to="/signin">Sign In Or Create Account</Link>}
              </Col>
            </Row>
          </Container>
        </nav>
      </div>
    </>
  );
};
