import * as firebase from "firebase";
import React from "react";
import "./App.css";
import { useGlobal } from "reactn";
import ReactGA from "react-ga";
import { State } from "reactn/default";
import { Switch, BrowserRouter, Route } from "react-router-dom";
import { TopNav } from "./components/TopNav";
import { Todo } from "./components/Todo/Todo";
import { SignInOrUp } from "./components/Auth/SignInOrUp";


ReactGA.initialize("UA-81209694-5");
ReactGA.pageview(window.location.pathname + window.location.search);

const init = async (setGlobalState: (setGlobalState: State) => void) => {
  const user = firebase.auth().currentUser;
  console.log(user);

  setGlobalState({
    editor: "",
    projects: [],
    archives: [],
    user: null,
  });
};

export const App = () => {
  const [, setGlobalState] = useGlobal();


  init(setGlobalState);


  return (
    <>
      <BrowserRouter>
        <TopNav />
        <Switch>
          <Route exact path="/" component={Todo} />
          <Route exact path="/signin" component={SignInOrUp} />
        </Switch>
      </BrowserRouter>
    </>
  );
};
