
import React, { FC } from "react";
import {
  Row, Col,
} from "reactstrap";
import { v4 } from "uuid";
import { TodoCard } from "../TodoCard";
import { IndexedTodo } from "./IndexedTodo";

interface InboxProps{
  indexedTodos: Array<IndexedTodo>
}

export const Inbox: FC<InboxProps> = ({
  indexedTodos,
}) => {
  const notDone = indexedTodos.filter((x) => !x.todo.done);
  return (
    <>
      <Row>
        <Col xs="12">
          <h1>Inbox</h1>
        </Col>
        <Col xs="12">
          <h3>全て</h3>
        </Col>
        <Col xs="12">
          {notDone.map((x: IndexedTodo) => (
            <TodoCard
              key={v4()}
              todo={x}
            />
          ))}
        </Col>
      </Row>
    </>
  );
};
