import { Line } from "./Line";
import { LineWithNumber } from "./Parsers/LineWithNumber";
import { TodoFactoryParser } from "./TodoFactory";
import { TodoLineWithNumber } from "./Parsers/TodoLineWithNumber";
import { TodoTree } from "./Parsers/TodoTree";

export class Todo {
  constructor(
    public title: Line<string>,
    public done: boolean,
    public project: string,
    public detail: Array<Line<string>> = [],
    public created: Line<Date> | undefined = undefined,
    public finished: Line<Date> | undefined = undefined,
    public deadline: Line<Date> | undefined = undefined,
    public tag: Array<Line<string>> = [],
    public other: Array<Line<string>> = [],
    public subTodo: Array<Todo> = [],
  ) {}

  public static makeSingleTodo(
    todoLineWithNumber: TodoLineWithNumber,
    todoLines: Array<LineWithNumber>,
  ): Todo {
    const todo = new Todo(
      todoLineWithNumber.title,
      todoLineWithNumber.done,
      todoLineWithNumber.projectName,
    );

    const loop = (lines: Array<LineWithNumber>, acc: Todo): Todo => {
      const [h, ...t] = lines;
      if (h === undefined) {
        return acc;
      }
      const newTodo = TodoFactoryParser.router(h, acc);
      if (newTodo.added) {
        return loop(t, newTodo.todo);
      }
      return loop(t, acc);
    };
    return loop(todoLines, todo);
  }

  public static makeTodo(todoTree: TodoTree): Todo {
    const { todoLines, subTodoLines, todoLineWithNumber } = todoTree;
    const todo = this.makeSingleTodo(todoLineWithNumber, todoLines);
    todo.subTodo = subTodoLines.map((x) => this.makeTodo(x));
    return todo;
  }
}
