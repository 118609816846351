import { Either, right, left } from "fp-ts/lib/Either";
import { Todo } from "./Todo";
import { ProjectLineWithNumber } from "./Parsers/ProjectLineWithNumber";
import { LineWithNumber } from "./Parsers/LineWithNumber";
import { TodoLineWithNumber } from "./Parsers/TodoLineWithNumber";
import { Project } from "./Project";

export class TodoFactory {
  public static readonly START: RegExp = /(^ -|^-) \[(?<done> |x)\] (?<title>.+)/;

  public static readonly TAG: RegExp = /(^ {2,3}-) # (?<arr>.+)/;

  public static readonly PROJECT: RegExp = /## (?<project>.+)/;

  public static readonly FINISHED: RegExp = /(^ {2,3}-) > (?<date>.+)/;

  public static readonly DEADLINE: RegExp = /(^ {2,3}-) @ (?<date>.+)/;

  public static readonly CREATED: RegExp = /(^ {2,3}-) : (?<date>.+)/;

  public static readonly DETAIL: RegExp = /(^ {2,3}-) (?<detail>.+)/;

  public static removeUnusedNoneProject(projects: Array<Project>): Array<Project> {
  
    return projects.reduce((acc, now) => {
      if(now.project === "None" && now.todo.length === 0) {
        return acc;
      }
      return [...acc, now];
    }, new Array<Project>())

  }

  // - [ ] title
  //   - detail
  //   - : 20200424T20:00:00
  //   - @ 20200501T20:00:00 (deadline)
  //   - > 20200429T00:00:00 (finished)
  //   - # tag1,tag2
  public static parse(md: string): Either<string, Array<Project>> {
    const lines: Array<LineWithNumber> = md.split("\n").map((line, num) => ({ num, line }));
    return right(
      TodoFactory.removeUnusedNoneProject(
        ProjectLineWithNumber.groupingProjects(lines).flatMap((x) =>
          TodoLineWithNumber.todoGroupings(x),
        ))
    );
  }
}
