import * as firebase from "firebase";
import React, { FC } from "react";
import { useGlobal } from "reactn";
import "./Viewer.css";
import { cloneDeep } from "lodash-es";
import { TodoContainer } from "./TodoContainer";
import { Project } from "../../models/Todo/Project";
import { TodoStringify } from "../../models/Todo/TodoStringify";
import { Todo } from "../../models/Todo/Todo";
import { updateDb } from "../../models/DB/RealtimeDB";


export const Viewer: FC = () => {
  const [globalState, setGlobalState] = useGlobal();

  /**
   * Move done todos to Archive
   */
  const archiveDoneTodos = () => {
    const newGlobalState = cloneDeep(globalState);

    const doneTodos = newGlobalState.projects.flatMap((p: Project) => p.todo.filter((t) => t.done));

    doneTodos.forEach((done: Todo) => {
      const archive = newGlobalState.archives.find((p: Project) => p.project === done.project);

      if (archive === undefined) {
        newGlobalState.archives.push(new Project(done.project, [done]));
        return;
      }
      archive.todo.push(done);
    });

    newGlobalState.projects.forEach((p: Project) => {
      p.todo = p.todo.filter((t) => !t.done);
    });

    newGlobalState.projects = newGlobalState.projects.filter((x: Project) => x.todo.length > 0);
    newGlobalState.editor = TodoStringify.stringify(newGlobalState.projects);

    setGlobalState(newGlobalState);
    const user = firebase.auth().currentUser;
    updateDb(user, "projects", JSON.stringify(newGlobalState.projects));
    updateDb(user, "archives", JSON.stringify(newGlobalState.archives));
    updateDb(user, "editor", newGlobalState.editor);
  };

  if (globalState.archives === undefined) {
    const newGlobalState = cloneDeep(globalState);
    newGlobalState.archives = [];
    setGlobalState(newGlobalState);
  }

  return (
    <>
      <TodoContainer projects={globalState.projects} archives={globalState.archives} archiveDoneTodos={archiveDoneTodos} />
    </>
  );
};
