import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col } from "reactstrap";
import { Editor } from "../Editor/Editor";
import { Viewer } from "../Viewer/Viewer";
// import { CreateTodo } from "../Viewer/CreateTodo";
import "./Todo.css";

export const Todo = () => {
  const [, setIsOpenState] = useState(false);
  const modalOpen = () => {
    setIsOpenState(true);
  };

  const titleRef = useRef<HTMLInputElement>(null);

  const routerKeys = (e: any) => {
    if (e.target?.localName !== "body") {
      return;
    }

    if (e.key === "n") {
      modalOpen();
      titleRef.current?.focus();
    }
  };

  const addKeydownListener = () => {
    document.addEventListener("keyup", (e: KeyboardEvent) => routerKeys(e), false);
  };

  const removeKeydownListener = () => {
    document.removeEventListener("keyup", routerKeys, false);
  };

  useEffect(() => {
    addKeydownListener();
    return removeKeydownListener();
  }, []);

  return (
    <>
      {/* <CreateTodo
        isOpen={isOpenState}
        setIsOpen={setIsOpenState}
        titleRef={titleRef}
        todoState={todoState}
        setTodoState={setTodoState}
      /> */}
      <Container>
        <Row>
          <Col sm={{ size: "6", order: "1" }} xs={{ size: "12", order: "2" }}>
            <Editor />
          </Col>
          <Col sm={{ size: "6", order: "1" }} xs={{ size: "12", order: "1" }}>
            <Viewer />
          </Col>
        </Row>
      </Container>
    </>
  );
};
