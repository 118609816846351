const Config = () => {
  if (process.env.NODE_ENV === "development") {
    return {
      firebase: {
        apiKey: process.env.REACT_APP_FB_PROD_API_KEY,
        authDomain: process.env.REACT_APP_FB_PROD_AUTH_DOMAIN,
        databaseURL: process.env.REACT_APP_FB_PROD_DATABASE_URL,
        storageBucket: process.env.REACT_APP_FB_PROD_STORAGE_BUCKET,
        messagingSender_Id: process.env.REACT_APP_FB_PROD_MESSAGING_SENDER_ID,
        applicationId: process.env.REACT_APP_FB_PROD_APP_ID,
      },
    };
  }

  if (process.env.NODE_ENV === "test") {
    return {
      firebase: {
        apiKey: process.env.REACT_APP_FB_TEST_API_KEY,
        authDomain: process.env.REACT_APP_FB_TEST_AUTH_DOMAIN,
        databaseURL: process.env.REACT_APP_FB_TEST_DATABASE_URL,
        storageBucket: process.env.REACT_APP_FB_TEST_STORAGE_BUCKET,
        messagingSender_Id: process.env.REACT_APP_FB_TEST_MESSAGING_SENDER_ID,
        applicationId: process.env.REACT_APP_FB_TEST_APP_ID,
      },
    };
  }

  return {
    firebase: {
      apiKey: process.env.REACT_APP_FB_PROD_API_KEY,
      authDomain: process.env.REACT_APP_FB_PROD_AUTH_DOMAIN,
      databaseURL: process.env.REACT_APP_FB_PROD_DATABASE_URL,
      storageBucket: process.env.REACT_APP_FB_PROD_STORAGE_BUCKET,
      messagingSender_Id: process.env.REACT_APP_FB_PROD_MESSAGING_SENDER_ID,
      applicationId: process.env.REACT_APP_FB_PROD_APP_ID,
    },
  };
};

export const firebaseConfig = Config().firebase;
