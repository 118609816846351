import { TodoFactory } from "../NewTodoFactory";
import { LineWithNumber } from "./LineWithNumber";

export class ProjectLineWithNumber {
  constructor(public name: string, public blocks: Array<LineWithNumber>) {}

  public static groupingProjects(
    lines: Array<LineWithNumber>,
    acc: Array<ProjectLineWithNumber> = [{ name: "None", blocks: [] }],
  ): Array<ProjectLineWithNumber> {
    const [h, ...t] = lines;

    if (h === undefined) {
      return acc;
    }

    if (TodoFactory.PROJECT.test(h.line)) {
      const name = TodoFactory.PROJECT.exec(h.line)?.groups?.project;
      if (name !== undefined) {
        acc.push({ name, blocks: [] });
        return ProjectLineWithNumber.groupingProjects(t, acc);
      }
    }

    acc[acc.length - 1].blocks.push(h);
    return ProjectLineWithNumber.groupingProjects(t, acc);
  }
}
