
const START = /(?<indent>^ *)- \[( |x)\].+/;
const CONTENT = /(?<indent>^ +)- .+/;
const HEAD_SPACE_ONLY = /(?<indent>^ +$)/;
const PROJECT = /##.*/;


const getBaseIndent = (all: Array<string>, nowLine: number): string | undefined => {

    if (nowLine < 0) {
        return undefined;
    }

    const startIndent = START.exec(all[nowLine])?.groups?.indent;
    if (startIndent !== undefined && startIndent !== null) {
        return startIndent;
    }

    return getBaseIndent(all, nowLine - 1);
}

/**
 * 指定した行の先頭に連続していくつの半角スペースがあるかをカウントする。
 * @param line 行
 */
const getLineHeadSpace = (line: string): number | undefined => {
    return HEAD_SPACE_ONLY.exec(line)?.groups?.indent?.length;
}


/**
 * numberで指定した数の半角スペースを持つstringを返す
 * @param count スペースの数
 */
const makeSpace = (count: number): string | undefined => {
    
    if(count < 0) {
        return undefined;
    }
    return [...Array(count)].reduce((acc, now) => ` ${acc}`, "");
}


/**
 *  - [ ] の行の場合,その行の頭についている改行 + space数+ 2を返す
 * それ以外のcontentの場合、改行 + その行の頭についているspace数を返す
 * 先頭にスペースだけが存在している行の場合、その上にある一番最初の - [ ] 行に付与されれているスペース + 2を返す
 */
export const getIndentAndLineBreak = (all: string, nowLine: number): string | undefined => {

    const allLines = all.split("\n");
    const line = allLines[nowLine];

    const startIndent = START.exec(line)?.groups?.indent;

    if (startIndent !== undefined) {
        return `\n  ${startIndent}`;
    }

    const contentIndent = CONTENT.exec(line)?.groups?.indent;
    if (contentIndent !== undefined) {
        return `\n${contentIndent}`;
    }

    const baseIndent = getBaseIndent(allLines, nowLine);

    if (baseIndent !== undefined) {
        const baseIndentNum = getLineHeadSpace(baseIndent);
        const nowIndentNum = getLineHeadSpace(line);

        if (baseIndentNum !== undefined && nowIndentNum !== undefined) {
            const num = makeSpace((baseIndentNum + 2) - nowIndentNum);
            if(num !== undefined)
            return num;
        }
    }

    return undefined;
}

export const getTodoIndentAndLineBreak = (all: string, nowLine: number): string | undefined => {

    const allLines = all.split("\n");
    const line = allLines[nowLine];

    const startIndent = START.exec(line)?.groups?.indent;

    if (startIndent !== undefined) {
        return `\n`;
    }

}

export const getLastBlockLineNumber = (all: string, nowLineNumber: number): number => {

    const allLines = all.split("\n");
    const line = allLines[nowLineNumber];

    return getLastBlockLineNumberRec(allLines, nowLineNumber)
}

const getLastBlockLineNumberRec = (allLines: Array<string>, nowLineNumber: number, startLine: number = nowLineNumber): number => {
    
    const nowLine = allLines[nowLineNumber];
    if(nowLine === undefined) {
        return nowLineNumber - 1;
    }
    
    const start = START.exec(nowLine)?.groups?.indent;
    if(start !== undefined && start === "" && nowLineNumber !== startLine ) {
        return nowLineNumber - 1;
    }

    const project = PROJECT.exec(nowLine);
    if(project !== null && nowLineNumber !== startLine ) {
        return nowLineNumber - 1;
    }
    
    return getLastBlockLineNumberRec(allLines, nowLineNumber + 1, startLine);

}