import * as firebase from "firebase";
import React, { FC } from "react";
import { Container, Row, Col } from "reactstrap";
import { v4 } from "uuid";
import Moment from "moment";
import { useGlobal } from "reactn";
import { cloneDeep } from "lodash-es";
import { TodoStringify } from "../../models/Todo/TodoStringify";
import { IndexedTodo } from "./TodoBoxes/IndexedTodo";
import { SubTodoCard } from "./SubTodoCard";
import { IndexedSubTodo } from "./TodoBoxes/IndexedSubTodo";
import { Line } from "../../models/Todo/Line";
import { updateDb } from "../../models/DB/RealtimeDB";

interface TodoCardProps {
  todo: IndexedTodo
}


export const TodoCard: FC<TodoCardProps> = ({ todo }) => {
  const [globalState, setGlobalState] = useGlobal();

  const handleChangeDone = () => {
    const newGlobalState = cloneDeep(globalState);
    newGlobalState.projects[todo.projectIndex].todo[todo.index].done = !newGlobalState.projects[todo.projectIndex].todo[todo.index].done;

    // finishedの更新
    if (newGlobalState.projects[todo.projectIndex].todo[todo.index].done) {
      const titleNum = newGlobalState.projects[todo.projectIndex].todo[todo.index].title.num;

      newGlobalState.projects[todo.projectIndex].todo[todo.index].finished = new Line(titleNum + 1, new Date());
    } else {
      newGlobalState.projects[todo.projectIndex].todo[todo.index].finished = undefined;
    }

    newGlobalState.editor = TodoStringify.stringify(newGlobalState.projects);
    setGlobalState(newGlobalState);
    updateDb(firebase.auth().currentUser, "editor", newGlobalState.editor);
    updateDb(firebase.auth().currentUser, "projects", JSON.stringify(newGlobalState.projects));
  };

  return (
    <>
      <Container className="todo-card">
        <Row className="viewer" key={v4()}>
          <Col xs="1" onClick={() => handleChangeDone()}>
            {todo.todo.done ? "✔" : "□"}
          </Col>
          <Col xs="11">
            {todo.todo.title.value}
          </Col>
          <Col xs="4">
            {todo.todo.deadline?.value ? <p className="deadline">{Moment(todo.todo.deadline.value).format("YYYY年MM月DD日")}</p> : <></>}
          </Col>
          <Col xs="4">
            {todo.todo.project}
          </Col>
          <Col xs="4">
            {todo.todo.tag.map((x) => x.value).join("/")}
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            {todo.todo.subTodo.map((x, i) => (
              <SubTodoCard
                key={v4()}
                todo={new IndexedSubTodo(todo.projectIndex, todo.index, [i], x)}
              />
            ))}
          </Col>
        </Row>
      </Container>
    </>
  );
};
