import { LineWithNumber } from "./LineWithNumber";
import { ProjectLineWithNumber } from "./ProjectLineWithNumber";
import { TodoFactory } from "../NewTodoFactory";
import { TodoAndSubTodos } from "./TodoAndSubTodos";
import { Todo } from "../Todo";
import { Line } from "../Line";
import { TodoTree } from "./TodoTree";
import { Project } from "../Project";

export class TodoLineWithNumber {
  constructor(
    public projectName: string,
    public title: Line<string>,
    public done: boolean,
    public lines: Array<LineWithNumber>,
    public subTodoLines: Array<TodoLineWithNumber>,
  ) {}

  public static todoGroupings(projectStrings: ProjectLineWithNumber): Project {
    const todos = this.makeTodos(
      this.todoGrouping(projectStrings).map((x) => this.makeTodoTree(x)),
    );
    const projectName = projectStrings.name;
    return new Project(projectName, todos);
  }

  private static todoGrouping(projectStrings: ProjectLineWithNumber): Array<TodoAndSubTodos> {
    return TodoLineWithNumber.todoGroupingRec(projectStrings.name, projectStrings.blocks).map(
      (x) => {
        return TodoLineWithNumber.extractSubTodoBlockRec(x, x.lines);
      },
    );
  }

  public static makeTodoTree(todoAndSubTodos: TodoAndSubTodos): TodoTree {
    const { todoLineWithNumber, todoLines, subTodoLines } = todoAndSubTodos;
    return new TodoTree(
      todoLineWithNumber,
      todoLines,
      subTodoLines.flatMap((x) =>
        TodoLineWithNumber.todoGrouping(
          new ProjectLineWithNumber(todoLineWithNumber.projectName, x),
        ).map((y) => TodoLineWithNumber.makeTodoTree(y)),
      ),
    );
  }

  public static makeTodos(todoTrees: Array<TodoTree>): Array<Todo> {
    return todoTrees.map((x) => Todo.makeTodo(x));
  }

  private static todoGroupingRec(
    projectName: string,
    lines: Array<LineWithNumber>,
    acc: Array<TodoLineWithNumber> = [],
  ): Array<TodoLineWithNumber> {
    const [h, ...t] = lines;
    // 玉が尽きたらaccを返す
    if (h === undefined) {
      return acc;
    }

    // スタート
    if (TodoFactory.START.test(h.line)) {
      const start = TodoFactory.START.exec(h.line);
      // undefinedになる事はありえないがコンパイラを納得させるため
      if (start?.groups?.title !== undefined && start?.groups?.done !== undefined) {
        acc.push({
          title: new Line(h.num, start.groups.title),
          done: start.groups.done === "x",
          lines: [],
          projectName,
          subTodoLines: [],
        });
        return TodoLineWithNumber.todoGroupingRec(projectName, t, acc);
      }
      // undefinedになる事はありえないがコンパイラを納得させるためとりあえずaccを返すことにしとく
      return acc;
    }

    if (acc.length > 0) {
      acc[acc.length - 1].lines.push(h);
      return TodoLineWithNumber.todoGroupingRec(projectName, t, acc);
    }
    return TodoLineWithNumber.todoGroupingRec(projectName, t, acc);
  }

  // TodoとSubTodoのLineをわけて返す
  private static extractSubTodoBlockRec(
    todoLineWithNumber: TodoLineWithNumber,
    lines: Array<LineWithNumber>,
    todoBlocksAcc: Array<LineWithNumber> = [],
    subTodoBlocksAcc: Array<Array<LineWithNumber>> = [],
  ): TodoAndSubTodos {
    const [h, ...t] = lines;
    if (h === undefined) {
      return new TodoAndSubTodos(todoLineWithNumber, todoBlocksAcc, subTodoBlocksAcc);
    }
    if (TodoFactory.START.test(h.line.slice(2))) {
      subTodoBlocksAcc.push([new LineWithNumber(h.num, h.line.slice(2))]);
      return TodoLineWithNumber.extractSubTodoBlockInSubTodoRec(
        todoLineWithNumber,
        t,
        todoBlocksAcc,
        subTodoBlocksAcc,
      );
    }

    todoBlocksAcc.push(h);
    return TodoLineWithNumber.extractSubTodoBlockRec(
      todoLineWithNumber,
      t,
      todoBlocksAcc,
      subTodoBlocksAcc,
    );
  }

  // SubTodoのブロックだけ固めて返す
  private static extractSubTodoBlockInSubTodoRec(
    todoLineWithNumber: TodoLineWithNumber,
    lines: Array<LineWithNumber>,
    todoBlocksAcc: Array<LineWithNumber> = [],
    subTodoBlocksAcc: Array<Array<LineWithNumber>> = [],
  ): TodoAndSubTodos {
    const [h, ...t] = lines;

    if (h === undefined) {
      return TodoLineWithNumber.extractSubTodoBlockRec(
        todoLineWithNumber,
        lines,
        todoBlocksAcc,
        subTodoBlocksAcc,
      );
    }

    const head = h.line.slice(2);
    if (head.startsWith("  ") || head.startsWith("   ")) {
      subTodoBlocksAcc[subTodoBlocksAcc.length - 1].push(
        new LineWithNumber(h.num, h.line.slice(2)),
      );
      return TodoLineWithNumber.extractSubTodoBlockInSubTodoRec(
        todoLineWithNumber,
        t,
        todoBlocksAcc,
        subTodoBlocksAcc,
      );
    }
    return TodoLineWithNumber.extractSubTodoBlockRec(
      todoLineWithNumber,
      lines,
      todoBlocksAcc,
      subTodoBlocksAcc,
    );
  }
}
