import { TodoLineWithNumber } from "./TodoLineWithNumber";
import { LineWithNumber } from "./LineWithNumber";

export class TodoTree {
  constructor(
    public todoLineWithNumber: TodoLineWithNumber,
    public todoLines: Array<LineWithNumber>,
    public subTodoLines: Array<TodoTree>,
  ) {}
}
