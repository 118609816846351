import React, { FC } from "react";
import { Row, Col } from "reactstrap";
import { v4 } from "uuid";
import { TodoCard } from "../TodoCard";
import { IndexedTodo } from "./IndexedTodo";


interface DoneProps {
  indexedTodos: Array<IndexedTodo>;
}

export const Done: FC<DoneProps> = ({ indexedTodos: projects }) => {
  const done = projects
    .filter((x) => x.todo.done);

  return (
    <>
      <Row>
        <Col xs="12">
          <h1>Done</h1>
        </Col>
        <Col xs="12">
          <h3>全て</h3>
        </Col>
        <Col xs="12">
          {done.map((x: IndexedTodo) => (
            <TodoCard key={v4()} todo={x} />
          ))}
        </Col>
      </Row>
    </>
  );
};
