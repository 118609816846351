import moment from "moment";
import { Todo } from "./Todo";
import { Line } from "./Line";
import { Project } from "./Project";
import { TodoFactory } from "./NewTodoFactory";

export class TodoStringify {
  public static stringify(projects: Array<Project>, indent: string = ""): string {
    return projects.map((x) => TodoStringify.stringifyProject(x, indent)).join("");
  }

  private static stringifyProject(project: Project, indent: string): string {
    const projectTitle = project.project;
    return `## ${projectTitle}\n${project.todo
      .map((x) => TodoStringify.stringifySingle(x, indent))
      .join("")}`;
  }

  private static stringifyTodoMultiple(todos: Array<Todo>, indent: string): string {
    return todos.map((x) => TodoStringify.stringifySingle(x, indent)).join("");
  }

  private static stringifySingle(todo: Todo, indent: string): string {
    const start = new Line(0, `${indent}${TodoStringify.startLine(todo)}`);

    const deadLine = TodoStringify.addingOr(
      indent,
      "  - @ ",
      TodoStringify.stringifyOr(todo.deadline),
    );

    const created = TodoStringify.addingOr(
      indent,
      "  - : ",
      TodoStringify.stringifyOr(todo.created),
    );

    const finished = TodoStringify.addingOr(
      indent,
      "  - > ",
      TodoStringify.stringifyOr(todo.finished),
    );
    const detail = TodoStringify.getDetail(todo.detail, indent);

    const tag = TodoStringify.arrayAddingOr(indent, "  - # ", todo.tag, ",");
    const other = TodoStringify.arrayAddingOr(indent, "", todo.other, "");

    const compareFn = (a: Line<string> | undefined, b: Line<string> | undefined): number => {
      if (a !== undefined && b !== undefined) {
        return a.num - b.num;
      }
      return -1;
    };

    const subTodo = TodoStringify.stringifyTodoMultiple(todo.subTodo, `${indent}  `);
    // detail is array, need concat
    return [start, deadLine, created, finished, tag, other]
      .concat(detail)
      .filter((x) => x !== undefined)
      .sort((a, b) => compareFn(a, b))
      .map((x) => x?.value)
      .concat([subTodo])
      .join("\n");
  }

  private static startLine(todo: Todo): string {
    const base = "-";
    const done = todo.done ? "[x]" : "[ ]";
    const { title } = todo;
    return `${base} ${done} ${title.value}`;
  }

  private static stringifyOr(date: Line<Date> | undefined): Line<string> | undefined {
    if (date === undefined) {
      return undefined;
    }
    return new Line(date.num, moment(date.value).format("YYYY-MM-DDTHH:mm:ss"));
  }

  private static addingOr(
    indent: string,
    prefix: string,
    value: Line<string> | undefined,
  ): Line<string> | undefined {
    if (value === undefined) {
      return undefined;
    }
    return new Line(value.num, `${indent}${prefix}${value.value}`);
  }

  private static getDetail(details: Array<Line<string>>, indent: string): Array<Line<string>> {
    return details.map((x) => new Line(x.num, `${indent}  - ${x.value}`));
  }

  private static arrayAddingOr(
    indent: string,
    prefix: string,
    values: Array<Line<string>>,
    delimiter: string,
  ): Line<string> | undefined {
    if (values.length === 0) {
      return undefined;
    }
    const { num } = values[0];
    return new Line(num, `${indent}${prefix}${values.map((x) => x.value).join(delimiter)}`);
  }
}
