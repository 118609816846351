
import React, { FC, useState } from "react";
import {
  Container, Row, Col, Button,
} from "reactstrap";
import { Today } from "./TodoBoxes/Today";
import { Inbox } from "./TodoBoxes/Inbox";
import { Done } from "./TodoBoxes/Done";
import { Project } from "../../models/Todo/Project";
import { Archive } from "./TodoBoxes/Archive";
import { IndexedTodo } from "./TodoBoxes/IndexedTodo";
import { Todo } from "../../models/Todo/Todo";

interface TodoContainerProps {
  projects: Array<Project>,
  archives: Array<Project>,
  archiveDoneTodos: () => void
}

enum ViewMode {
  INBOX,
  TODAY,
  SEVEN_DAYS,
  DONE,
  ARCHIVE,
}

export const TodoContainer: FC<TodoContainerProps> = ({
  projects, archives, archiveDoneTodos,
}) => {
  const [modeState, setModeState] = useState(ViewMode.INBOX);
  const [searchWordState, setSearchWordState] = useState("");
  const handleModeChange = (mode: ViewMode) => {
    setModeState(mode);
  };

  const handleChangeSearchWord = (word: string) => {
    setSearchWordState(word);
  };

  const contains = (todo: Todo, word: string): boolean => {
    return todo.title.value.includes(word) ||
    todo.detail.map((t) => t.value).join("").includes(word) ||
    todo.project.includes(word) ||
    todo.tag.map((t) => t.value).join("").includes(word) ||
    todo.other.map((t) => t.value).join("").includes(word) ||
    todo.subTodo.some((x) => contains(x, word));
  };

  const filter = (indexedTodos: Array<IndexedTodo>, word: string): Array<IndexedTodo> => {
    if (word === "") {
      return indexedTodos;
    }

    return indexedTodos.filter((x) => contains(x.todo, word));
  };


  const indexedTodos = filter((() => {
    if (modeState === ViewMode.ARCHIVE) {
      return archives.flatMap((project, projectIndex) =>
        project.todo.map((todo, todoIndex) =>
          new IndexedTodo(projectIndex, todoIndex, todo)));
    }
    return projects.flatMap((project, projectIndex) =>
      project.todo.map((todo, todoIndex) =>
        new IndexedTodo(projectIndex, todoIndex, todo)));
  })(), searchWordState);


  return (
    <Container>
      <Row style={{ marginBottom: "1rem" }}>
        <Col sm="6" xs="12">
          <input placeholder="Filter" value={searchWordState} onChange={(e) => handleChangeSearchWord(e.target.value)} />
        </Col>
        <Col sm="6" xs="12" style={{ textAlign: "right" }}>
          <Button onClick={archiveDoneTodos}>Archive Done Todos</Button>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <Row>
            <Col xs="3">
              <Button onClick={() => handleModeChange(ViewMode.INBOX)}>INBOX</Button>
            </Col>
            <Col xs="3">
              <Button onClick={() => handleModeChange(ViewMode.TODAY)}>TODAY</Button>
            </Col>
            <Col xs="3">
              <Button onClick={() => handleModeChange(ViewMode.DONE)}>DONE</Button>
            </Col>
            <Col xs="3">
              <Button onClick={() => handleModeChange(ViewMode.ARCHIVE)}>ARCHIVE</Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          {modeState === ViewMode.INBOX ? <Inbox indexedTodos={indexedTodos} /> : <></>}
        </Col>
        <Col xs="12">
          {modeState === ViewMode.TODAY ? <Today indexedTodos={indexedTodos} /> : <></>}
        </Col>
        <Col xs="12">
          {modeState === ViewMode.DONE ? <Done indexedTodos={indexedTodos} /> : <></>}
        </Col>
        <Col xs="12">
          {modeState === ViewMode.ARCHIVE ? <Archive archives={indexedTodos} /> : <></>}
        </Col>
      </Row>
    </Container>
  );
};
