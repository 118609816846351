import { cloneDeep } from "lodash-es";
import Moment from "moment";
import { Todo } from "./Todo";
import { Line } from "./Line";
import { LineWithNumber } from "./Parsers/LineWithNumber";

export class TodoFactoryParser {
  private static readonly START: RegExp = /(^ -|^-) \[(?<done> |x)\] (?<title>.+)/;

  private static readonly TAG: RegExp = /(^ {2,3}-) # (?<arr>.+)/;

  private static readonly FINISHED: RegExp = /(^ {2,3}-) > (?<date>.+)/;

  private static readonly DEADLINE: RegExp = /(^ {2,3}-) @ (?<date>.+)/;

  private static readonly CREATED: RegExp = /(^ {2,3}-) : (?<date>.+)/;

  private static readonly DETAIL: RegExp = /(^ {2,3}-) (?<detail>.+)/;

  public static router(line: LineWithNumber, todo: Todo): { added: boolean; todo: Todo } {
    if (TodoFactoryParser.FINISHED.test(line.line)) {
      return {
        added: true,
        todo: TodoFactoryParser.getDate(line, todo, TodoFactoryParser.FINISHED, "finished"),
      };
    }
    if (TodoFactoryParser.DEADLINE.test(line.line)) {
      return {
        added: true,
        todo: TodoFactoryParser.getDate(line, todo, TodoFactoryParser.DEADLINE, "deadline"),
      };
    }
    if (TodoFactoryParser.CREATED.test(line.line)) {
      return {
        added: true,
        todo: TodoFactoryParser.getDate(line, todo, TodoFactoryParser.CREATED, "created"),
      };
    }
    if (TodoFactoryParser.TAG.test(line.line)) {
      return {
        added: true,
        todo: TodoFactoryParser.getTag(line, todo, TodoFactoryParser.TAG),
      };
    }
    if (TodoFactoryParser.DETAIL.test(line.line)) {
      return { added: true, todo: TodoFactoryParser.getDetail(line, todo) };
    }

    if (line.line.replace(" ", "").replace("\n", "").length === 0) {
      return { added: true, todo };
    }

    return { added: false, todo };
  }

  private static getTag(line: LineWithNumber, todo: Todo, regex: RegExp): Todo {
    const tag = regex
      .exec(line.line)
      ?.groups?.arr?.split(",")
      .map((x) => new Line(line.num, x));
    if (tag === undefined || tag.length === 0) {
      return todo;
    }
    const newTodo = cloneDeep(todo);
    newTodo.tag = tag;
    return newTodo;
  }

  private static getDate(
    line: LineWithNumber,
    todo: Todo,
    reg: RegExp,
    paramName: "finished" | "deadline" | "created",
  ): Todo {
    const param = reg.exec(line.line)?.groups?.date;

    if (param === undefined) {
      return todo;
    }

    const newTodo = cloneDeep(todo);
    try {
      // eslint-disable-next-line prefer-destructuring
      // eslint-disable-next-line no-param-reassign
      newTodo[paramName] = new Line(line.num, Moment(new Date(param)).toDate());
      return newTodo;
    } catch {
      return todo;
    }
  }

  private static getDetail(line: LineWithNumber, todo: Todo): Todo {
    const detail = TodoFactoryParser.DETAIL.exec(line.line)?.groups?.detail;

    if (detail === undefined) {
      return todo;
    }

    const newTodo = cloneDeep(todo);
    newTodo.detail.push(new Line(line.num, detail));
    return newTodo;
  }
}
