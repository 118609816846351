
import React, {
  useState, useEffect, useRef, FC,
} from "react";
import { Container, Row, Col } from "reactstrap";
import firebase from "firebase";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { firebaseAuth } from "../../Firebase";

const uiConfig = {
  signInFlow: "popup",
  signInSuccessUrl: "/",
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
  ],
};


export const SignInOrUp = () => {
  return (
    <Container>
      <Row>
        <Col xs="12">
          <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebaseAuth} />
        </Col>
      </Row>
    </Container>
  );
};
